import styled from 'styled-components'
import { sizes, media, fontSize } from "../helpers/styles";

export const RowContainer = styled.div`
   width: 100%;
   float: none;
   clear: both;
   display: block;

   ${({bgColor}) => bgColor &&`
      background-color: ${bgColor};
   `}
`

export const generateColumns = (columns) => {
   return `${(1 / columns) * 100}%`
   // if (columns < 2) return ` 1fr`
   // let cols = ``
	// for (let i = 0; i < columns; i++) {
	// 	cols += ` 1fr`
	// }
	// return cols
}

export const RowInnerContainer = styled.div`
   display: block;
   text-align: center;
   padding: 6vh 0px;
   margin: 0px auto;
   width: 100%;
   box-sizing: border-box;
   max-width: ${sizes.hd}px;

   ${({hero}) => hero &&`
      padding-top: 3vh;
   `}

   & > div, & > img {
      font-size: ${fontSize};
      width: 100%;
      padding: 1em 1vw;
      clear: both;
      float: none;
      display: block;
      box-sizing: border-box;
   }

   ${({columns}) => columns &&`
      & > div, & > img {
         clear: none;
         display: inline-block;
         vertical-align: middle;
         width: ${generateColumns(columns)};
      }
   `}

   ${({top}) => top &&`
      vertical-align: top;
      & > div, & > img {
         vertical-align: top;
      }
   `}

   ${({bottom}) => bottom &&`
      vertical-align: bottom;
      & > div, & > img {
         vertical-align: bottom;
      }
   `}

   ${media.tablet`
      ${({columns}) => columns && columns > 2 && columns !== 3 &&`
         & > div, & > img {
            width: ${generateColumns(columns-2)};
         }
      `}
   `}

   ${({innerRow}) => innerRow &&`
      padding: 0px;
   `}

   ${media.phone`
      & > div, & > img {
         display: block;
         clear: both;
         width: 100%;
      }
   `}

   img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 33vh;
   }
`