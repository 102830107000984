import React from "react";
import Protocol from "./pages/Protocol";
import { Route, Switch } from "react-router-dom";

export const Routes = () => {
   return (
      <div>
         <Switch>
            <Route exact path="/" component={Protocol} />
         </Switch>
      </div>
   );
};
