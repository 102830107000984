import styled from 'styled-components'
import { colors, media } from '../../helpers/styles'

import shoulder from '../../../images/specialties/shoulder.svg'
import spine from '../../../images/specialties/spine.svg'
import hip from '../../../images/specialties/hip.svg'
import knee from '../../../images/specialties/knee.svg'
import hand from '../../../images/specialties/hand.svg'
import foot from '../../../images/specialties/foot.svg'

const specialtyIcons = {
   shoulder: shoulder,
   spine: spine,
   hip: hip,
   knee: knee,
   hand: hand,
   foot: foot
}

export const SpecialtyContainer = styled.div`
   display: block;
   text-align: center;
   font-size: 0px;
   width: 100%;
   padding: 1vh;
   grid-gap: 0px;
   margin: 1vh auto 2vh auto;
   clear: both;
   float: none;
   box-sizing: border-box;
   background-color: rgba(255,255,255,0.5);
   border-radius: 10px;

   ${media.phone`
      padding: 1vh 0px;
   `}
`

export const Specialty = styled.div`
   width: 100%;
   position: relative;
   height: auto;
   padding: 0px;
   background: none;
   border: 0px none;
   cursor: pointer;
   display: inline-block;
   clear: both;
   float: none;
   vertical-align: top;
   font-size: 0.9em;
   overflow: hidden;
   max-width: 16.6%;

   &.active {
      color: ${colors.premium};
   }

   p {
      font-size: 1.3vh;
      padding: 0.5em 0px;
      line-height: 1;
      color: inherit;
      font-weight: bold;
      max-width: 100%;
      display: block;
      text-overflow: ellipsis;

      ${media.tablet`
         font-size: 2vw;
      `}
   }

   ${media.phone`
      max-width: 33.33%;
   `}
`

export const SpecialtyIcon = styled.div`
   width: 10vh;
   height: auto;
   max-width: 100%;
   margin: 0px auto;
   display: block;
   float: none;
   clear: both;
   background-color: ${colors.primary};
   border-radius: 10vh;
   transform: translate3d(0,0,0) scale(0.9);
   background-image: url(${specialtyIcons.shoulder});
   background-repeat: no-repeat;
   background-position: center center;
   background-size: contain;
   transition: all 200ms ease-out;

   &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 100%;
   }

   &.active {
      background-color: ${colors.premium};
      transform: translate3d(0,0,0) scale(1);
   }

   ${({icon}) => icon &&`
      background-image: url(${specialtyIcons[icon]});
   `}
`