import React from "react";
import Row from "../components/Row";
import Protocol from "../components/Protocol";
import GlobalStyles from "../GlobalStyles";

import { ViewContainer } from "../components/Layout/styles";

import { PageContent } from "./styles";

export default React.memo(() => {
   return (
      <ViewContainer style={{ padding: "0vh !important", top: "-200px" }}>
         <PageContent>
            <GlobalStyles />
            <Row>
               <Protocol />
            </Row>
         </PageContent>
      </ViewContainer>
   );
});
