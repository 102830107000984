import styled from 'styled-components'
import { colors, media } from '../../helpers/styles'

export const LayerContainer = styled.div`
   text-align: left;
   display: inline-block;
   float: none;
   clear: none;
   display: inline-block;
   vertical-align: top;
   width: 50%;
   padding: 2em;
   box-sizing: border-box;

   ${media.phone`
      display: block;
      clear: both;
      width: 100%;
      padding: 0.5em;
   `}
`

export const LayerHeader = styled.div`
   padding: 0px;
   margin: 0px;
   border-bottom: 2px solid ${colors.secondary};

   h3 {
      color: ${colors.secondary};
   }
   p {
      line-height: 1;
      padding-top: 0px;
      padding-bottom: 0.5em;
      opacity: 0.6;
   }

   ${({level}) => level && level === 'level-2' &&`
      border-color: ${colors.premium};
      h3 {
         color: ${colors.premium};
      }
   `}
`

export const LayerForms = styled.ul`
   margin: 0px auto;
   padding: 0.5em 0px 0px 1.5em;

   li {
      padding: 0.3em;
      color: ${colors.secondary};

      p {
         color: #333;
         font-size: 1em;
         padding: 0px;
         line-height: 1.3;

         &.form-name {
            font-weight: bold;
         }
         &.form-note {
            opacity: 0.6;
            font-size: 0.8em;
         }
      }
   }

   ${({level}) => level && level === 'level-2' &&`
      li {
         color: ${colors.premium};
      }
   `}
`