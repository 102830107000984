import React from "react";
import { LayerContainer, LayerHeader, LayerForms } from "./styles";

const index = React.memo(({ title, subTitle, forms, parentKey }) => {
   return (
      <LayerContainer>
         <LayerHeader level={parentKey}>
            <h3>{title}</h3>
            <p>{subTitle}</p>
         </LayerHeader>
         <LayerForms level={parentKey}>
            {forms &&
               forms.map((form, idx) => (
                  <li key={`${parentKey}-form-${idx}`}>
                     <p className="form-name">{form.name}</p>
                     {form.note && <p className="form-note">({form.note})</p>}
                  </li>
               ))}
         </LayerForms>
      </LayerContainer>
   );
});

export default index;
