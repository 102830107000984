import React from "react";
import { RowContainer, RowInnerContainer } from "./styles";

const index = ({ ...props }) => {
   return (
      <RowContainer bgColor={props.bgColor}>
         <RowInnerContainer {...props}>{props.children}</RowInnerContainer>
      </RowContainer>
   );
};

export default index;
