import React, { useState, useEffect } from "react";
import FormList from "./FormList";
import Timeline from "./Timeline";
import Specialties from "./Specialties";
import { ProtocolContainer, ProtocolLevels } from "./styles";

import specialties from "../../data/specialties";

const Protocol = React.memo(() => {
   const [slider, setSlider] = useState(null);
   const [specialty, switchSpecialty] = useState(0);

   const startSlider = () => {
      const interval = setInterval(() => {
         switchSpecialty((specialty) =>
            specialty >= specialties.length - 1 ? 0 : specialty + 1
         );
      }, 4000);
      setSlider(interval);
   };

   const stopSlider = (specialtyId) => {
      clearInterval(slider);
      switchSpecialty(specialtyId);
   };

   useEffect(() => {
      startSlider();
   }, []);

   return (
      <ProtocolContainer>
         <div>
            <h2>
               Specialties and Musculoskeletal Outcomes Shared Platform (MOSP)
            </h2>
            <p style={{ paddingTop: "0px" }}>
               Select to show <b>LEVEL II</b> forms below
            </p>
         </div>
         <Specialties specialty={specialty} selectSpecialty={stopSlider} />
         <ProtocolLevels>
            <FormList
               key="level-1"
               parentKey="level-1"
               title="Required protocol"
               subTitle="Forms included automatically"
               forms={[
                  {
                     name: "Patient-Reported Outcomes Measurement Information System (PROMIS) Short Form v2.0: Physical Function 10a",
                  },
                  {
                     name: "PROMIS Short Form v1.0: Pain Interference 6b",
                  },
                  {
                     name: "PROMIS Short Form v2.0: Upper Extremity 7a",
                     note: "For upper extremity only: humerus, forearm, hand",
                  },
               ]}
            />
            <FormList
               key="level-2"
               parentKey="level-2"
               title="Level II protocol"
               subTitle="Additional forms (based on fracture)"
               forms={specialties[specialty].forms}
            />
         </ProtocolLevels>
         <Timeline />
      </ProtocolContainer>
   );
});

export default Protocol;
