import React from "react";
import { SpecialtyContainer, Specialty, SpecialtyIcon } from "./styles";

import specialties from "../../../data/specialties";

const SpecialtyButton = React.memo(
   ({ name, icon, selectSpecialty, active }) => {
      return (
         <Specialty
            onClick={selectSpecialty}
            className={active ? "active" : ""}
         >
            <SpecialtyIcon icon={icon} className={active ? "active" : ""} />
            <p>{name}</p>
         </Specialty>
      );
   }
);

const index = React.memo(({ specialty, selectSpecialty }) => {
   return (
      <SpecialtyContainer>
         {specialties.map(({ name, icon }, idx) => (
            <SpecialtyButton
               key={`specialty-${idx}`}
               name={name}
               icon={icon}
               selectSpecialty={() => selectSpecialty(idx)}
               active={specialty === idx}
            />
         ))}
      </SpecialtyContainer>
   );
});

export default index;
