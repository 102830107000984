import styled from 'styled-components'
import {rgba} from 'polished'
import { media, colors, setupMsGrid } from '../../helpers/styles'

const bgColor = `${colors.gray}`

export const TimelineContainer = styled.div`
   width: 100%;
   padding: 0px 2vh;
   margin: 2vh 0px;
   padding-bottom: 6vh;
   grid-gap: 0px;
   box-sizing: border-box;
   max-width: 100%;
   position: relative;
   display: -ms-grid;
   display: grid;
   -ms-grid-columns: 0px 3% 5% 8% 20% 25% 25% auto;
   grid-template-columns: 0px 3% 5% 8% 20% 25% 25% auto;

   &::before {
      content: "";
      height: 1vh;
      width: 98%;
      position: relative;
      display: block;
      float: none;
      clear: both;
      position: absolute;
      top: 1vh;
      -webkit-transform: translate3d(0,-100%,0);
      -ms-transform: translate3d(0,-100%,0);
      transform: translate3d(-50%,-100%,0);
      border-radius: 2vh;
      z-index: 1;
      border-top: 1vh dotted rgb(32,198,167);
      left: 50%;
   }

   ${setupMsGrid(8)}
`

export const TimelineKey = styled.ul`
   display: inline-block;
   width: auto;
   margin: 3em auto 0px auto;
   padding: 0.5em;
   border: 1px solid #EEE;
   background-color: #FFF;
   list-style: none !important;
   color: #555;
   border-radius: 6px;

   li {
      float: none;
      clear: none;
      display: inline-block;
      margin: 0.1em;
      padding: 0.5em;
      line-height: 1;

      &::before {
         content: "";
         width: 1em;
         height: 1em;
         margin: 0px;
         margin-right: 0.5em;
         display: inline-block;
         border-radius: 1em;
         float: none;
         clear: none;
         vertical-align: top;
         background-color: #828bAF;
      }

      &:last-child::before {
         width: 0.5em;
         height: 0.5em;
         margin: 0.25em;
         margin-right: 0.5em;
         background-color: rgb(32, 198, 167);
      }
   }
`

export const TimePoint = styled.div`
   height: 2vh;
   width: 100%;
   position: relative;
   overflow: visible;
   z-index: 5;
   transform: translate3d(0,-0.5vh,0);

   &::before {
      content: "";
      display: block;
      height: 100%;
      width: 2px;
      background-color: rgba(0,0,0,0.1);
      position: absolute;
      right: 0;
      top: 100%;
      transform: translate3d(50%,0,0);
   }

   &::after {
      content: "";
      display: block;
      border: 0.2em solid ${bgColor};
      background: #828BAF;
      width: 2.3vh;
      height: 2.3vh;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 2.3vh;
      z-index: 5;
      transform: translate3d(50%,-50%,0);
   }

   p {
      display: block;
      height: auto;
      width: auto;
      white-space: nowrap;
      line-height: 1;
      position: absolute;
      z-index: 2;
      padding: 0px;
      margin: 0px;
      right: 0;
      top: 160%;
      text-align: center;
      font-size: 1.3vh;
      transform: translate3d(50%,100%,0);
   }

   &:first-child {
      &::before {
         right: auto;
         left: 0;
         transform: translate3d(-50%,0,0);
      }
      &::after {
         right: auto;
         left: 0;
         transform: translate3d(-50%,-50%,0);
      }
      p {
         right: auto;
         left: 0;
         transform: translate3d(-50%,100%,0);
      }
   }

   &:nth-child(2) {
      &::before {
         height: 350%;
      }
      p { top: 420%; }
   }
   &:nth-child(3) {
      &::before {
         height: 225%;
      }
      p { top: 300%; }
   }
`

export const TimelineContinued = styled.div`
   width: 40%;
   position: absolute;
   z-index: 3;
   right: 0;
   top: 0;
   height: 100%;
   white-space: nowrap;
   background: ${bgColor};
   background: -moz-linear-gradient(left,  ${rgba(bgColor,0)} 0%, ${rgba(bgColor,1)} 100%);
   background: -webkit-linear-gradient(left,  ${rgba(bgColor,0)} 0%,${rgba(bgColor,1)} 100%);
   background: linear-gradient(to right,  ${rgba(bgColor,0)} 0%,${rgba(bgColor,1)} 100%);
   transform: translate3d(0, -50%, 0);
`

export const TimePointContainer = styled.div`
   position: absolute;
   right: 0;
   top: 50%;
   width: auto;
   height: auto;
   text-align: right;
   transform: translate3d(0, -50%, 0);
   color: #828BAF;
   font-size: 0px;

   span {
      display: inline-block;
      position: relative;
      float: none;
      clear: none;
      margin: 0px;
      font-size: 1vh;
      width: 1em;
      height: 1em;
      border: 0.5em solid ${bgColor};
      background-color: #828BAF;
      border-radius: 2em;
      line-height: 1;
      opacity: 0.9;
      padding: 0.5em;
      font-weight: bold;
      text-align: center;
      transform: translate3d(0, -16%, 0) scale(0.9);

      &:nth-child(2) {
         left: 0.5em;
         opacity: 0.6;
         transform: translate3d(0, -16%, 0) scale(0.8);
      }
      &:nth-child(3) {
         opacity: 0.3;
         transform: translate3d(0, -16%, 0) scale(0.7);
      }
      ${media.phone`
         font-size: 0.75vh;
      `}
   }
`