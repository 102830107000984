import styled from 'styled-components'

export const ProtocolContainer = styled.div`
   display: block;
   float: none;
   clear: both;
   width: 100%;
`

export const ProtocolLevels = styled.div`
   display: block;
   width: 100%;
   height: auto;
   padding: 0px 0px 3vh 0px;
   box-sizing: border-box;
`