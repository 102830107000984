import styled, { css } from "styled-components"

export const fontSize = `2vh`;

export const colors = {
	primary: `#042D98`,
	secondary: `#3B7FF6`,
   darkBlue: `#031D6D`,
   essentials: `#3B7FF6`,
   advantage: `#0A7A5B`,
	premium: `#860072`,
	yellow: `#FFF500`,
	gray: `#EDF0F6`,
	body: `#1E1F22`,
	header: `#042D98`
}

export const sizes = {
	hd: 1200,
	desktop: 1024,
	tablet: 980,
	phone: 768,
}


export const setupMsGrid = (count, columns) => {
   let colCounter = 1;
   let currentRow = 1;
   let maxColumns = columns || count
   let str = '';
   for (let c = 0; c < count; c += 1) {
      if (colCounter > maxColumns) {
         colCounter = 1;
         currentRow += 1;
      }
      str += `
         & > *:nth-child(${c + 1}) {
            -ms-grid-column: ${colCounter};
            -ms-grid-row: ${currentRow};
         }
      `
      colCounter += 1;
   }
   return str
}

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
	// use em in breakpoints to work properly cross-browser and support users
	// changing their browsers font-size: https://zellwk.com/blog/media-query-units/
	const emSize = sizes[label] / 16
	accumulator[label] = (...args) => css`
		@media (max-width: ${emSize}em) {
			${css(...args)};
		}
	`
	return accumulator
}, {})

export const ApplyButtonLink = styled.a`
   display: inline-block;
   float: none;
   clear: none;
   width: auto;
   align-self: center;
   justify-self: center;
   border-radius: 5em;
   font-size: 1.3em;
   line-height: 1;
   letter-spacing: 1px;
   font-weight: normal;
   text-align: center;
   padding: 0.6em 2em;
   cursor: pointer;
   border: 2px solid ${colors.primary};
   color: ${colors.primary};

   ${({secondary}) => secondary &&`
      border-color: ${colors.secondary};
      color: ${colors.secondary};
   `}

   ${({yellow}) => yellow &&`
      border-color: ${colors.yellow};
      color: ${colors.yellow};
   `}

   ${({solid, yellow, secondary}) => solid &&`
      color: #FFF;
      background-color: ${colors.primary};

      ${ secondary &&`
         background-color: ${colors.secondary};
      `}

      ${ yellow &&`
         background-color: ${colors.yellow};
      `}
   `}
`

export const IconBadgeContainer = styled.div`
   border-radius: 20vh;
   margin: 0px auto;
   float: none;
   color: #FFF;
   width: 10vh;
   text-align: center;
   position: relative;
   background-color: ${colors.primary};

   &::before {
      content: "";
      display: block;
      padding-top: 100%;
   }

   ${({secondary}) => secondary &&`
      background-color: ${colors.secondary};
   `}

   ${({yellow}) => yellow &&`
      background-color: ${colors.yellow};
   `}

   img {
      width: auto;
      height: auto;
      max-width: 50% !important;
      max-height: 50% !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(1.3);
   }
`