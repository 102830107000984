import styled from "styled-components";

export const PageContent = styled.div`
   padding: 8vh 0px 0px 0px;
   display: block;
   width: 100%;
   overflow-x: hidden;
   position: relative;
   max-width: 100vw;
   box-sizing: border-box;
   height: auto;

.formContent {
   text-align: left;
   padding: 0vh 4vw 0vh 2vw;
   font-size: 1.2em;
   min-height: 83vh;

   p {
      padding: 0em;
   }

   div {
      padding: 1em 0em;
   }
}


`