export default [
   {
      name: "Shoulder/elbow",
      icon: "shoulder",
      forms: [
         {
            name: "PROMIS: Global Health"
         },
         {
            name: "VAS Pain"
         },
         {
            name: "ASES Shoulder Assessment",
            note: "Proximal humerus only"
         },
         {
            name: "SANE"
         }
      ]
   },
   {
      name: "Spine",
      icon: "spine",
      forms: [
         {
            name: "PROMIS: Global Health"
         },
         {
            name: "Numeric Pain/VAS: Neck and Arm",
            note: "Cervical"
         },
         {
            name: "NDI",
            note: "Cervical"
         },
         {
            name: "Numeric Pain/VAS: Back and Leg",
            note: "Lumbar and sacrum"
         },
         {
            name: "ODI 2.1a",
            note: "Lumbar and sacrum"
         }
      ]
   },
   {
      name: "Hip",
      icon: "hip",
      forms: [
         {
            name: "PROMIS: Global Health"
         },
         {
            name: "VAS Pain"
         },
         {
            name: "HOOS Jr."
         }
      ]
   },
   {
      name: "Knee",
      icon: "knee",
      forms: [
         {
            name: "PROMIS: Global Health"
         },
         {
            name: "VAS Pain"
         },
         {
            name: "KOOS Jr."
         }
      ]
   },
   {
      name: "Hand/wrist",
      icon: "hand",
      forms: [
         {
            name: "PROMIS: Global Health"
         },
         {
            name: "VAS Pain"
         },
         {
            name: "Quick DASH"
         },
      ]
   },
   {
      name: "Foot/ankle",
      icon: "foot",
      forms: [
         {
            name: "PROMIS: Global Health"
         },
         {
            name: "VAS Pain"
         },
         {
            name: "FAAM"
         }
      ]
   }
]