import React from "react";
import {
   TimelineContainer,
   TimePoint,
   TimelineKey,
   TimelineContinued,
   TimePointContainer,
} from "./styles";

import protocols from "../../../data/protocols";

const index = () => {
   return (
      <>
         <TimelineContainer>
            {protocols.map(({ label }, idx) => (
               <TimePoint key={`time-point-${idx}`}>
                  <p>{label}</p>
               </TimePoint>
            ))}
            <TimelineContinued>
               <TimePointContainer>
                  <span>&middot;</span>
                  <span>&middot;</span>
                  <span>&middot;</span>
               </TimePointContainer>
            </TimelineContinued>
         </TimelineContainer>
         <TimelineKey>
            <li>Forms assigned</li>
            <li>Wearables data</li>
         </TimelineKey>
      </>
   );
};

export default index;
