import styled from "styled-components";

export const ViewContainer = styled.div`
	width: 100vw;
	height: 100%;
	overflow: visible;
   position: absolute;
   font-family: 'Suisse Intl', sans-serif;
   font-size: 1em;
   line-height: 1;
	top: 0;
	left: 0;
	text-align: center;
	display: block;
`;