import { createGlobalStyle } from 'styled-components'
import { colors } from './components/helpers/styles'

import SuisseRegularWoff2 from './fonts/SuisseIntl/SuisseIntl-Regular-WebS.woff2'
import SuisseRegularWoff from './fonts/SuisseIntl/SuisseIntl-Regular-WebS.woff'
import SuisseSemiBoldWoff2 from './fonts/SuisseIntl/SuisseIntl-SemiBold-WebS.woff2'
import SuisseSemiBoldWoff from './fonts/SuisseIntl/SuisseIntl-SemiBold-WebS.woff'

export default createGlobalStyle`
   @font-face {
      font-family: 'Suisse Intl';
      font-style: normal;
      font-weight: normal; /* or 400 */
      src:  url(${SuisseRegularWoff2}) format('woff2'),
            url(${SuisseRegularWoff}) format('woff');
   }
   @font-face {
      font-family: 'Suisse Intl';
      font-style: normal;
      font-weight: 600;
      src:  url(${SuisseSemiBoldWoff2}) format('woff2'),
            url(${SuisseSemiBoldWoff}) format('woff');
   }

   html {
      overflow-x: hidden;
   }

   body {
      font-size: 1.6vh;
   }

   h1, h2, h3, h4, h5, h6 {
      line-height: 1.3;
      margin: 1em auto 0px auto;
      padding: 0px;
   }
   p {
      line-height: 1.5;
      margin: 0px auto;
      padding: 1em 0px;
      letter-spacing: 0.02em;
   }
   *.secondary { color: ${colors.secondary}; }
   *.primary { color: ${colors.primary}; }
`