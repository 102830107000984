export default [
   {
      label: "Pre-Op",
      position: 0
   },
   {
      label: "+1 mo",
      position: 2.5
   },
   {
      label: "+3 mo",
      position: 7.5
   },
   {
      label: "+6 mo",
      position: 15
   },
   {
      label: "+1 yr",
      position: 30
   },
   {
      label: "+2 yr",
      position: 60
   },
   {
      label: "+3 yr",
      position: 90
   }
]